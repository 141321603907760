import React, { Fragment, ReactNode } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader

interface IProps {
    contentArr: ReactNode[];
    currentTab: number;
    handleTab: (_: number) => void;
    options?: {
        autoPlay: boolean;
        interval: number;
        infiniteLoop: boolean;
    };
}

const DefaultCarousel = ({ contentArr, currentTab, handleTab, options }: IProps) => {
    return (
        <Carousel
            swipeScrollTolerance={140}
            swipeable
            emulateTouch
            showStatus={false}
            showIndicators={false}
            showArrows={false}
            showThumbs={false}
            onChange={handleTab}
            selectedItem={currentTab}
            autoPlay={options?.autoPlay}
            interval={options?.interval}
            infiniteLoop={options?.infiniteLoop}
        >
            {contentArr.map((item: ReactNode, index: number) => (
                <Fragment key={index}>{item}</Fragment>
            ))}
        </Carousel>
    );
};

export default DefaultCarousel;
