import React from 'react';
import Link from 'next/link';
import Image from 'next/image';
import styled from 'styled-components';
import { useRecoilValue } from 'recoil';

import { hasAlertSelector } from '@/recoil';
import { TABLET, RESIZED_SCREEN_TABLET } from '@/models/breakPoints';

interface ILayout {
    children: React.ReactNode;
}

const TopMainWithBannerLayout = ({ children }: ILayout) => {
    const hasAlert = useRecoilValue(hasAlertSelector);

    return (
        <Container id="top_main_layout">
            <LogoContainer>
                <Image src="/img/plan/main_logo_color.png" alt="logo" width="115px" height="24px" />
                <HiddenTag>픽앤플랜</HiddenTag>
                <Link href="/notification">
                    <a>
                        <Image
                            src={hasAlert ? '/img/layout/new_alert_dot.png' : '/img/layout/new_alert.png'}
                            alt="alert"
                            width="28px"
                            height="28px"
                        />
                    </a>
                </Link>
            </LogoContainer>
            {children}
        </Container>
    );
};

const Container = styled.div`
    height: 100%;
`;

const LogoContainer = styled.div`
    ${({ theme }) => theme.flex.flexOnly};
    ${({ theme }) => theme.flex.justifyBetween};
    z-index: 998;
    width: 100%;
    height: 56px;
    /* padding: 20px; */
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 20px;
    padding-right: 20px;
    background-color: #f5f5f5;
    position: relative;
    @media (min-width: ${TABLET}px) {
        max-width: ${RESIZED_SCREEN_TABLET}px;
    }
`;

const HiddenTag = styled.h1`
    position: absolute;
    width: 1px;
    height: 1px;
    clip: rect(0 0 0 0);
    margin: -1px;
    overflow: hidden;
`;

export default TopMainWithBannerLayout;
