import { RESIZED_SCREEN_TABLET, TABLET } from '@/models/breakPoints';
import { useEffect, useState } from 'react';

function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
        width: 0,
        height: 0,
    });

    useEffect(() => {
        function handleResize() {
            if (typeof window !== 'undefined') {
                if (window.innerWidth >= TABLET)
                    setWindowSize({ width: RESIZED_SCREEN_TABLET, height: window.innerHeight });
                else {
                    setWindowSize({
                        width: window.innerWidth,
                        height: window.innerHeight,
                    });
                }
            }
        }

        window.addEventListener('resize', handleResize);

        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, []);
    return windowSize;
}

export default useWindowSize;
