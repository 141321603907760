import { useEffect } from 'react';
import { useRouter } from 'next/router';
import Image from 'next/image';
import styled from 'styled-components';

import { FontStyle } from '@/utils/StyleUtil';
import useModal from '@/hooks/useModal';
import SvgSelector from '@/components/Svg/SvgSelector';

const Candy250324ModalBanner = () => {
    const router = useRouter();
    const { addModal, removeCurrentModal } = useModal();

    const setTimer = () => {
        const targetDate = new Date().setHours(24, 0, 0, 0);
        window.sessionStorage.setItem('candy-250324', Math.round((targetDate - Date.now()) / 1000).toString());
    };

    const openModal = () => {
        addModal({
            key: 'popUp',
            props: {
                type: 'default',
                containerStyle: { borderRadius: '20px', overflow: 'hidden' },
                contentStyle: { padding: 0 },
                content: <Modal />,
            },
        });
    };

    const Modal = () => {
        return (
            <Container>
                <FlexContainer>
                    <CloseIconContainer onClick={removeCurrentModal}>
                        <SvgSelector svg="close" width={18} height={18} strokeWidth={1.6} stroke="#222222" />
                    </CloseIconContainer>
                </FlexContainer>
                <Title>
                    보험상품 추천받고
                    <br />
                    행운 캔디 뽑자!
                </Title>
                <ImageContainer>
                    <Image src="/img/event/candy_main@3x.png" alt="candy_main" width="210" height="334" />
                </ImageContainer>
                <Button
                    onClick={() => {
                        if (window.ReactNativeWebView) localStorage.setItem('eventPageParent', '/');
                        removeCurrentModal();
                        router.push('/event/candy-250324');
                    }}
                >
                    이벤트 자세히 보기
                </Button>
            </Container>
        );
    };

    useEffect(() => {
        if (!window.sessionStorage.getItem('candy-250324')) {
            setTimer();
            openModal();
        }
    }, []);

    return null;
};

const Container = styled.div`
    padding: 20px;
    background: linear-gradient(180deg, #fff 0%, #7aedb0 102.43%);
    width: 320px;
`;

const FlexContainer = styled.div`
    ${({ theme }) => theme.flex.flexOnly}
    ${({ theme }) => theme.flex.justifyEnd}
`;

const CloseIconContainer = styled.div`
    cursor: pointer;
    width: 28px;
    height: 28px;
    ${({ theme }) => theme.flex.flexOnly}
    ${({ theme }) => theme.flex.justifyEnd}
`;

const Title = styled.div`
    margin-top: 12px;
    font-family: 'Y Clover TTF';
    ${FontStyle(30, 42, 'bold', '#111111', 0)}
`;

const ImageContainer = styled.div`
    position: absolute;
    top: 169px;
    left: calc(50% - 105px);
`;

const Button = styled.div`
    cursor: pointer;
    margin-top: 200px;
    margin-bottom: 10px;
    padding: 12px 0;
    position: relative;
    border-radius: 6px;
    background-color: #049f66;
    ${FontStyle(14, 22, '500', '#ffffff', -1)}
`;

export default Candy250324ModalBanner;
