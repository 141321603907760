import Image from 'next/image';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';

import DotExplanation from '@/components/ContextPrinter/DotExplanation';
import DividerDefault from '@/components/Divider/Default';
import { isAppState } from '@/recoil';
import { SNSLIST } from '@/models/snsList';
import { FontStyle } from '@/utils/StyleUtil';
import { useRouter } from 'next/router';

const Review = () => {
    const isApp = useRecoilValue(isAppState);
    const router = useRouter();

    return (
        <Wrapper>
            <DotExplanation
                contents={[
                    {
                        parent: '프라임에셋 보험대리점(협회등록번호:2009058101호)',
                    },
                ]}
                parentStyle={FontStyle(12, 20, '400', '#ACACAC', -1)}
                containerStyle={{ padding: 0 }}
            />
            <DotExplanation
                contents={[
                    {
                        parent: '프라임에셋 심의필 제2024-07-0531호 (2024.07.08~2025.07.07)',
                        child: ['본 광고는 광고심의 기준을 준수하였으며, 유효기간은 심의일로부터 1년입니다.'],
                    },
                ]}
                parentStyle={FontStyle(12, 20, '400', '#ACACAC', -1)}
                childStyle={{ ...FontStyle(12, 20, '400', '#ACACAC', -1), marginLeft: '6px' }}
                containerStyle={{ padding: 0 }}
            />
            <DotExplanation
                contents={[
                    {
                        parent: '본 서비스는 고객이 부담해야할 별도의 수수료가 발생하지 않습니다',
                    },
                ]}
                parentStyle={FontStyle(12, 20, '400', '#ACACAC', -1)}
                containerStyle={{ padding: 0 }}
            />
            <DotExplanation
                contents={[
                    {
                        parent: '상기 내용은 보험대리점의 의견이며, 계약체결에 따른 이익 또는 손실은',
                        child: ['보험계약자 등에게 귀속됩니다.'],
                    },
                ]}
                parentStyle={FontStyle(12, 20, '400', '#ACACAC', -1)}
                childStyle={{ ...FontStyle(12, 20, '400', '#ACACAC', -1), marginLeft: '6px' }}
                containerStyle={{ padding: 0 }}
            />
            <DotExplanation
                contents={[
                    {
                        parent: '보험사 및 상품별로 상이할 수 있으므로,',
                        child: ['관련한 세부사항은 반드시 해당 약관을 참조하시기 바랍니다.'],
                    },
                ]}
                parentStyle={FontStyle(12, 20, '400', '#ACACAC', -1)}
                childStyle={{ ...FontStyle(12, 20, '400', '#ACACAC', -1), marginLeft: '6px' }}
                containerStyle={{ padding: 0 }}
            />
            <DotExplanation
                contents={[
                    {
                        parent: '보험사 상품별, 성별, 연령, 직업 등에 따라 가입 가능한 담보와',
                        child: ['가입금액, 보험료는 달라질 수 있습니다.'],
                    },
                ]}
                parentStyle={FontStyle(12, 20, '400', '#ACACAC', -1)}
                childStyle={{ ...FontStyle(12, 20, '400', '#ACACAC', -1), marginLeft: '6px' }}
                containerStyle={{ padding: 0 }}
            />
            <DividerDefault thickness={1} length="100%" color="#dadada" style={{ margin: '16px 0' }} />
            <DetailTextWrapper>
                <div>보험계약자가 기존 보험계약을 해지하고 새로운 보험계약을 체결하는 과정에서</div>
                <div>① 질병이력, 연령증가 등으로 가입이 거절되거나 보험료가 인상될 수 있습니다.</div>
                <div>② 가입 상품에 따라 새로운 면책기간 적용 및 보장 제한 등 기타 불이익이 발생할 수 있습니다.</div>
            </DetailTextWrapper>
            <DividerDefault thickness={1} length="100%" color="#dadada" style={{ margin: '16px 0' }} />
            <DetailTextWrapper>
                <div>서울특별시 광진구 아차산로 563, 2층 (대한제지빌딩)</div>
                <div>프라임에셋(주) 사업자등록번호 314-86-05946</div>
                <div>대표 이용진</div>
                <div>마케팅 · 제휴 문의 picknpr_marketing@primeasset.kr</div>
                <div>CopyrightⓒPRIME ASSET, ALL RIGHTS RESERVED.</div>
            </DetailTextWrapper>
            <PrivacyAndTerms>
                <div onClick={() => router.push('myPage/eula?type=policy')}>개인정보 처리방침</div>
                <DividerDefault thickness={1} color={'#DADADA'} direction="vertical" length="10px" />
                <div onClick={() => router.push('myPage/eula?type=terms')}>서비스 이용약관</div>
            </PrivacyAndTerms>
            <SnS>
                {SNSLIST.map(sns => {
                    return (
                        <a
                            key={sns.sns}
                            href={sns.url}
                            onClick={e => {
                                e.preventDefault();

                                if (isApp) window.ReactNativeWebView.postMessage(JSON.stringify({ sns: sns.url }));
                                else window.open(sns.url);
                            }}
                        >
                            <Image src={`/svg/${sns.sns}.svg`} alt={sns.sns} width={22} height={22} />
                        </a>
                    );
                })}
            </SnS>
        </Wrapper>
    );
};

const Wrapper = styled.footer`
    ${({ theme }) => theme.flex.flexColumn};
    justify-content: center;
    padding: 20px 20px 108px 20px;
    background-color: #eeeeee;
    /* white-space: pre-wrap; */
    word-break: keep-all;
`;

const DetailTextWrapper = styled.div`
    ${FontStyle(12, 20, '400', '#ACACAC', -1)};
`;

const PrivacyAndTerms = styled.div`
    display: flex;
    gap: 8px;
    margin-top: 18px;
    ${FontStyle(12, 20, '500', '#888888', -1)};
    align-items: center;
`;

const SnS = styled.div`
    display: flex;
    padding-top: 22px;
    column-gap: 20px;
`;

export default Review;
