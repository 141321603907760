export type EVENT_STATUS = {
    eventStatus: EVENT_PROCESS;
    eventEndAt: string;
    eventStartAt: string;
    earlyClosedAt: string | null;
};

export enum EVENT_PROCESS {
    NOT_OPENED = 'NOT-OPENED',
    EARLY_CLOSED = 'EARLY-CLOSED',
    EXPIRED = 'EXPIRED',
    IN_PROGRESS = 'IN-PROGRESS',
}

export type TICKET_STATUS = 'NO-TICKET' | 'UNUSED' | 'USED';
export type TICKET_CANDY = 'INVALID' | 'FIRST' | 'SECOND' | 'THIRD' | 'FOURTH' | 'EARLY-CLOSED';
export type TICKET_VERIFICATION = 'INVALID' | 'UNIDENTIFIED' | 'VERIFIED';
export type TICKET_VERIFICATION_RESULT = 'INVALID' | 'UNIDENTIFIED' | 'NOT-ADULT' | 'ALREADY-REGISTERED' | 'CONFIRMED';

export type TICKET = {
    ticketStatus: TICKET_STATUS;
    candyColor: TICKET_CANDY;
    verificationStatus: TICKET_VERIFICATION;
    verificationResult: TICKET_VERIFICATION_RESULT;
};
