import React, { CSSProperties } from 'react';
import styled from 'styled-components';

interface IContent {
    parent: string | React.ReactElement;
    child?: string[] | React.ReactElement[];
}

interface IProps {
    contents: IContent[];
    reverse?: boolean;
    outdent?: boolean;
    parentStyle?: Object;
    childStyle?: Object;
    containerStyle?: Object;
    strongStyle?: CSSProperties;
    strongIdx?: number[];
    className?: string;
}

function DotExplanation({
    contents,
    reverse = false,
    outdent = false,
    parentStyle,
    childStyle,
    containerStyle,
    strongStyle,
    strongIdx,
    className,
}: IProps) {
    return (
        <Container style={containerStyle} className={className}>
            {contents.map((content, pindex) => (
                <div key={pindex}>
                    <Text
                        style={strongIdx?.includes(pindex) ? strongStyle : parentStyle}
                        outdent={outdent}
                        reverse={reverse}
                    >
                        {reverse || <Dot>{'\u2022'}</Dot>}
                        {content.parent}
                    </Text>
                    {content.child?.map((child, cindex) => (
                        <Text
                            style={strongIdx?.includes(cindex) ? strongStyle : childStyle}
                            outdent={outdent}
                            reverse={!reverse}
                            key={cindex}
                        >
                            {!reverse || <Dot>{'\u2022'}</Dot>}
                            {child}
                        </Text>
                    ))}
                </div>
            ))}
        </Container>
    );
}

const Container = styled.div`
    border-radius: 8px;
    padding: 12px 20px;
    color: #acacac;
`;

const Text = styled.div<{ outdent: boolean; reverse: boolean }>`
    ${({ theme }) => theme.flex.flexOnly}
    margin-left: ${({ outdent, reverse }) => (outdent && reverse ? '11px' : 0)};
    white-space: pre-wrap;
`;

const Dot = styled.div`
    margin-right: 4px;
`;

export default React.memo(DotExplanation);
