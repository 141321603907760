import React, { ReactNode, CSSProperties } from 'react';
import styled from 'styled-components';

import { FontStyle } from '@/utils/StyleUtil';

interface IProps {
    prefixUri?: ReactNode;
    label: string | ReactNode;
    labelStyle?: CSSProperties;
    suffixUri?: ReactNode;
    className?: string;
    onClick?: Function;
}

function LabelUsingAffixIcon({ prefixUri, label, labelStyle, suffixUri, className, onClick }: IProps) {
    return (
        <Container
            className={className}
            onClick={() => {
                if (onClick) onClick();
            }}
        >
            <div className="left_contents">
                {prefixUri ? <div className="prefix_icon">{prefixUri}</div> : undefined}
                <div className="label" style={labelStyle}>
                    {label}
                </div>
            </div>
            {suffixUri}
        </Container>
    );
}

const Container = styled.div`
    ${({ theme }) => theme.flex.flexOnly}
    ${({ theme }) => theme.flex.justifyBetween}
    .left_contents {
        ${({ theme }) => theme.flex.flexOnly}
        .prefix_icon {
            ${({ theme }) => theme.flex.flexOnly}
            ${({ theme }) => theme.flex.justifyCenter}
            margin-right: 5px;
        }
        .label {
            ${FontStyle(14, 22, '400', '#444444', -1)}
        }
    }
`;

export default LabelUsingAffixIcon;
