import React, { useState, useMemo, lazy, Suspense } from 'react';
import styled from 'styled-components';

import DefaultCarousel from '@/components/Carousel/DefaultCarousel';
import dynamic from 'next/dynamic';

function Selector({ name, ...props }: { name: string }) {
    // const Component = lazy(() => import(`@/containers/common/banner/${name}`));
    const Component = dynamic(() => import(`@/containers/common/banner/${name}`));


    return (
        <Component {...props} />
    );
}

const Banner = ({ list }: { list: (string | undefined)[] }) => {
    const [currentTab, setCurrentTab] = useState(0);

    const BannerArr = useMemo(() => {
        return list.filter(e => e).map(name => <BannerContainer key={name} name={name!} />);
    }, [list]);

    return (
        <DefaultCarousel
            contentArr={BannerArr}
            currentTab={currentTab}
            handleTab={setCurrentTab}
            options={{
                autoPlay: true,
                interval: 3000,
                infiniteLoop: true,
            }}
        />
    );
};

const BannerContainer = styled(Selector)`
    margin: 0 20px;
    text-align: initial;
`;

export default Banner;
