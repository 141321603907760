import { useRouter } from 'next/router';
import Image from 'next/image';
import { setCookie } from 'cookies-next';
import styled from 'styled-components';

import useModal from '@/hooks/useModal';
import TitleNAdditionalContents from '@/components/ContextPrinter/TitleNAdditionalContents';
import { FontStyle } from '@/utils/StyleUtil';

const AppPrompt = () => {
    const router = useRouter();
    const { removeCurrentModal } = useModal();

    const onClickHideOneDay = () => {
        const targetDate = new Date().setHours(24, 0, 0, 0);
        const now = new Date().valueOf();
        setCookie('app-prompt', 0, { maxAge: Math.round((targetDate - now) / 1000) });
        removeCurrentModal();
    };

    const onClickDownload = () => {
        onClickHideOneDay();

        router.push('https://picknplan.onelink.me/ACKU/3cbyactj');
    };

    return (
        <Wrapper>
            <TitleNAdditionalContents
                title={`앱을 설치하고\n더 빠른 상담을 해보세요!`}
                subText="푸시 알림을 통해 보다 빠른 답변을 받아볼 수 있어요"
                titleStyle={{ padding: '0 10px' }}
                subTextStyle={{ padding: '0 10px' }}
            />
            <ImageWrapper>
                <Image src="/img/character/pick_cat@3x.png" width={100} height={100} alt="pick cat" />
            </ImageWrapper>
            <ButtonWrapper>
                <HideOneDay onClick={onClickHideOneDay}>하루 동안 보지 않기</HideOneDay>
                <Download onClick={onClickDownload}>앱 다운 받기</Download>
            </ButtonWrapper>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    padding: 50px 20px 20px 20px;
`;

const ImageWrapper = styled.div`
    position: absolute;
    top: -16px;
    right: 30px;
`;

const ButtonWrapper = styled.div`
    ${({ theme }) => theme.flex.flexOnly};
    margin-top: 25px;
    gap: 8px;
`;

const Button = styled.button`
    ${({ theme }) => theme.flex.flexOnly};
    ${({ theme }) => theme.flex.justifyCenter};
    width: 100%;
    height: 46px;
    border-radius: 6px;
    ${FontStyle(14, 22, '500', undefined, -1)};
`;

const HideOneDay = styled(Button)`
    border: 1px solid #0ec4cf;
    color: #0ec4cf;
`;

const Download = styled(Button)`
    color: #ffffff;
    background-color: #0ec4cf;
`;

export default AppPrompt;
