import React, { ReactElement, useEffect } from 'react';
import { useResetRecoilState, useSetRecoilState } from 'recoil';
import styled from 'styled-components';

import BottomTabLayouts from '@/layouts/bottomTab';
import TopMainWithBannerLayout from '@/layouts/topMainWithBanner';
import useOpenAppPrompt from '@/hooks/modal/useOpenAppPrompt';
import Banner from '@/containers/plan/index/Banner';
import TMGuaranteeMenu from '@/containers/plan/index/TMGuaranteeMenu';
import EtcGuaranteeMenu from '@/containers/plan/index/EtcGuaranteeMenu';
import Review from '@/containers/plan/index/Review';
import { SelectedIns, insuredPersonState } from '@/recoil/plan';
import { interestIdState } from '@/recoil/myPlan';
import { defaultUser } from '@/models/userInput';
import TaggedQuestions from '@/containers/plan/index/TaggedQuestions';
import useEvent from '@/hooks/event/candy-250324/useEvent';
import Candy250324ModalBanner from '@/containers/event/candy-250324/modal/Banner';

const HomePage = () => {
    const setSelIns = useSetRecoilState(SelectedIns);
    const setUserInfo = useSetRecoilState(insuredPersonState);
    const resetInterestId = useResetRecoilState(interestIdState);

    useOpenAppPrompt();

    useEffect(() => {
        setSelIns(0);
        setUserInfo(defaultUser);
        resetInterestId();
    }, [resetInterestId, setSelIns, setUserInfo]);

    const { mainBannerRender } = useEvent(true);

    const banner = [
        mainBannerRender ? 'event_candy-250324-l' : undefined,
        'main_consulting',
        'main_ina',
        'main_settlement',
        'main_pet',
    ];

    return (
        <Container>
            <Banner list={banner} />
            <TMGuaranteeMenu />
            <TaggedQuestions />
            <EtcGuaranteeMenu />
            <Review />
            {mainBannerRender && <Candy250324ModalBanner />}
        </Container>
    );
};

const Container = styled.div`
    position: relative;
    //height: 100%;
    background-color: #f5f5f5;
    /* margin-top: 12px; */
`;

HomePage.getTopLayout = (page: ReactElement) => {
    return <TopMainWithBannerLayout>{page}</TopMainWithBannerLayout>;
};

HomePage.getBottomLayout = (page: ReactElement) => {
    return <BottomTabLayouts selectedItemKey="plan">{page}</BottomTabLayouts>;
};

export default HomePage;
