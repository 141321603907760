import React from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { useSetRecoilState } from 'recoil';
import styled from 'styled-components';

import useWindowSize from '@/hooks/useWindowSize';
import ShadowContainer from '@/components/CustomContainer/ShadowContainer';
import { PensionPage, PetPage, SelectedIns } from '@/recoil/plan';
import { IETCCard, ETCGuaranteeItems } from '@/models/plan/main';
import { defaultSize } from '@/models/breakPoints';
import { FontStyle } from '@/utils/StyleUtil';
import media from '@/styles/media';
import { useRouter } from 'next/router';
import { INQUIRY } from '@/models/plan';

const EtcGuaranteeMenu = () => {
    const { width } = useWindowSize();
    const setInsuranceType = useSetRecoilState(SelectedIns);
    const setPensionPageVisited = useSetRecoilState(PensionPage);
    const setPetPageVisited = useSetRecoilState(PetPage);
    setPensionPageVisited(false);
    setPetPageVisited(false);
    const router = useRouter();

    const handleInquiryClick = () => {
        setInsuranceType(INQUIRY);
        router.push('/plan/user-info');
    };

    const verticalCard = ({ prefixLabel, suffixLabel, image, code }: IETCCard) => {
        return (
            <VCardContainer>
                <Link href={'/plan/user-info'}>
                    <div
                        onClick={() => {
                            setInsuranceType(code);
                        }}
                    >
                        <div className="prefix_text">{prefixLabel}</div>
                        <h2 className="suffix_text">
                            {width >= defaultSize ? suffixLabel.default : suffixLabel?.small ?? suffixLabel.default}
                        </h2>
                        <VImageContainer>
                            <Image src={image} alt={prefixLabel} width="50px" height="46px" />
                        </VImageContainer>
                    </div>
                </Link>
            </VCardContainer>
        );
    };

    const insuranceCard = ({ prefixLabel, suffixLabel, image, code }: IETCCard) => {
        return (
            <Link href={'/plan/user-info'}>
                <Container onClick={() => setInsuranceType(code)} inquiry={false}>
                    <ImageContainer>
                        <Image src={image} alt={prefixLabel} width="50px" height="46px" />
                    </ImageContainer>
                    <TextContainer>
                        <div className="prefix_text">{prefixLabel}</div>
                        <h2 className="suffix_text">
                            {width >= defaultSize ? suffixLabel.default : suffixLabel?.small ?? suffixLabel.default}
                        </h2>
                    </TextContainer>
                </Container>
            </Link>
        );
    };

    const inquiryCard = () => {
        return (
            <Link href={'/ask/user-info'}>
                <Container inquiry={true} onClick={() => setInsuranceType(INQUIRY)}>
                    <ImageContainer>
                        <Image src={'/img/character/plandog50.png'} alt={'planDog'} width="50px" height="46px" />
                    </ImageContainer>
                    <TextContainer>
                        <TitleContainer>
                            <div className="prefix_text">플래너 문의하기</div>
                            <HotTag>추천</HotTag>
                        </TitleContainer>
                        <h2 className="suffix_text">상품을 찾기 어렵다면 문의하세요!</h2>
                    </TextContainer>
                </Container>
            </Link>
        );
    };

    const horizontalCard = ({ prefixLabel, suffixLabel, image, code }: IETCCard) => {
        return (
            <Link href={'/plan/user-info'}>
                <div onClick={() => setInsuranceType(code)}>
                    <ShadowContainer>
                        <HCardContainer>
                            <div>
                                <div className="prefix_text">{prefixLabel}</div>
                                <h2 className="suffix_text">{suffixLabel.default}</h2>
                            </div>
                            <HImageContainer>
                                <Image src={image} alt={prefixLabel} width="46px" height="36px" />
                            </HImageContainer>
                        </HCardContainer>
                    </ShadowContainer>
                </div>
            </Link>
        );
    };

    return (
        <EtcContainer>
            {/* <VerticalContainer> */}
            {insuranceCard(ETCGuaranteeItems[0])}
            {insuranceCard(ETCGuaranteeItems[1])}
            {/* </VerticalContainer> */}
            {insuranceCard(ETCGuaranteeItems[2])}
            {inquiryCard()}
            <div style={{ height: '30px' }} />
        </EtcContainer>
    );
};

// const EtcContainer = styled.div`
//     padding: 2px 20px 0 20px;
//     background-color: #f5f5f5;
//     white-space: pre-wrap;

//     .prefix_text {
//         margin: 0;
//         ${FontStyle(20, 28, '700', '#111111', -1)};
//     }
//     .suffix_text {
//         margin: 4px 0 0 0;
//         ${FontStyle(14, 22, '400', '#888888', -1)};
//     }
// `;

const EtcContainer = styled.div`
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    padding: 2px 20px 0 20px;
    gap: 10px;
    .prefix_text {
        margin: 0;
        ${FontStyle(18, 26, '700', '#111111', -1)};
    }
    .suffix_text {
        margin: 4px 0 0 0;
        ${FontStyle(12, 20, '400', '#ACACAC', -1)};
    }
`;

const VCardContainer = styled(ShadowContainer)`
    width: 50%;

    ${media.default} {
        height: 222px;
    }
`;

const Container = styled.div<{ inquiry: boolean }>`
    border-radius: 10px;
    background-color: #fff;
    background-color: ${({ inquiry }) => (inquiry ? '#F6FCFD' : '#FFF')};
    display: flex;
    gap: 20px;
    padding: 20px;
    padding-right: 0px;
    ${({ inquiry }) => inquiry && { border: `2px solid #0EC4CF` }};
`;

const VImageContainer = styled.div`
    ${({ theme }) => theme.flex.flexOnly};
    ${({ theme }) => theme.flex.justifyEnd};
    margin-top: 12px;
`;

const ImageContainer = styled.div``;

const HCardContainer = styled.div`
    ${({ theme }) => theme.flex.flexOnly};
    ${({ theme }) => theme.flex.justifyBetween};
`;

const HImageContainer = styled.div`
    ${({ theme }) => theme.flex.flexOnly};
    ${({ theme }) => theme.flex.justifyCenter};
`;

const VerticalContainer = styled.div`
    ${({ theme }) => theme.flex.flexOnly};
    gap: 10px;
    margin-bottom: 20px;
`;

const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const TitleContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
`;

const HotTag = styled.div`
    ${FontStyle(12, 18, '500', '#FF2525', -1)};
    border-radius: 10px;
    background-color: #ffebeb;
    padding: 1px 8px;
    text-align: center;
`;

export default EtcGuaranteeMenu;
