import { useEffect, useState } from 'react';
import { DateTime } from 'luxon';

import { API, URLs } from '@/api';
import { EVENT_PROCESS, EVENT_STATUS, TICKET, TICKET_CANDY } from '@/models/event/candy-250324';

export const fetchEvent = async () => {
    const eventState = (await API.get(URLs.event.state)) as unknown as EVENT_STATUS;
    // return 조기종료;
    return eventState;
};

export const fetchDraw = async () => {
    const { result } = (await API.post(URLs.event.draw)) as unknown as {
        result: TICKET_CANDY;
    };
    return result;
};

// 이벤트 종료 기한으로부터 일 수 차이 계산, 양수의 경우 now < endAt (now에서 +N일 하면 endAt)
export const daysLeft = (endAt: EVENT_STATUS['eventEndAt']) => {
    const now = DateTime.now();
    const endAtToDateTime = DateTime.fromJSDate(new Date(endAt));
    const diffDay = Math.ceil(endAtToDateTime.diff(now, 'days').days);
    return diffDay;
};

// 이벤트가 진행 상태와 상관 없이 시작을 하였는가
export const isOpened = (eventStatus: EVENT_STATUS['eventStatus']) => {
    return eventStatus !== EVENT_PROCESS.NOT_OPENED;
};

// 서버 기준 이벤트가 종료 되었는가
export const isExpired = (eventStatus: EVENT_STATUS['eventStatus']) => {
    return (
        isOpened(eventStatus) && (eventStatus === EVENT_PROCESS.EARLY_CLOSED || eventStatus === EVENT_PROCESS.EXPIRED)
    );
};

// 이벤트가 사전 종료 되었다면 그 날의 자정이 지났는가
export const isExpiredAtMidnight = (props: EVENT_STATUS) => {
    if (props.earlyClosedAt) {
        const now = DateTime.now();
        const midnight = DateTime.fromISO(props.earlyClosedAt).startOf('day').plus({ days: 1 });
        return now >= midnight;
    }

    return isExpired(props.eventStatus);
};

// 이벤트 종료 + 7일 이전인 경우
export const isWithin7Days = (endAt: EVENT_STATUS['eventEndAt']) => {
    return daysLeft(endAt) > -7;
};

// 이벤트 완전 종료일 경우
export const isWithout30Days = () => {
    const now = DateTime.now();
    const completelyExpiredDate = DateTime.fromISO('2025-05-19T00:00:00');
    return now >= completelyExpiredDate;
};

export default function useEvent(immediately: boolean = false) {
    const [eventState, setEventState] = useState<EVENT_STATUS | null>(null);
    const [ticket, setTicket] = useState<TICKET | null>(null);

    const [mainBannerRender, setMainBannerRender] = useState<boolean>(false);
    const [drawButtonRender, setDrawButtonRender] = useState<boolean>(false);
    const [drawModalRender, setDrawModalRender] = useState<boolean>(false);
    const [expiredContainerRender, setExpiredContainerRender] = useState<boolean>(false);
    const [winningResultRender, setWinningResultRender] = useState<boolean>(false);

    const fetchEventState = async () => {
        const res = await fetchEvent();
        setEventState(res);
        return res;
    };

    const fetchTicketState = async (ev: EVENT_STATUS) => {
        const res = (await API.get(URLs.event.ticket)) as unknown as TICKET;
        setTicket(res);

        // 이벤트 종료로부터 30일이 지난 경우
        const allDisable = isWithout30Days();

        const mainBannerCondition = !allDisable && isOpened(ev.eventStatus) && !isExpiredAtMidnight(ev);
        setMainBannerRender(mainBannerCondition);
        // 이벤트가 진행중이며, 응모권이 있으며, 경품 추첨을 하지 않은 경우
        const drawbuttonCondition =
            !isExpired(ev.eventStatus) && res.ticketStatus === 'UNUSED' && res.candyColor === 'INVALID';
        setDrawButtonRender(drawbuttonCondition);
        // 이벤트 진행 상태와 상관없이 종료일 + 7일 이내이며, 경품 추첨을 했으며, 인증을 하지 않은 경우
        const drawModalCondition =
            isWithin7Days(ev.eventEndAt) && res.candyColor !== 'INVALID' && res.verificationStatus !== 'VERIFIED';
        setDrawModalRender(drawModalCondition);
        // 이벤트가 종료되었으며, drawModalCondition이 아닌 경우
        const expiredContainerCondition = allDisable || (isExpired(ev.eventStatus) && !drawModalCondition);
        setExpiredContainerRender(expiredContainerCondition);
        // 이벤트 진행 상태, 인증 여부에 상관없이 경품 추첨을 한 경우
        const winningResultCondition = !allDisable && res.candyColor !== 'INVALID';
        setWinningResultRender(winningResultCondition);
    };

    const init = () => {
        fetchEventState().then(es => fetchTicketState(es));
    };

    useEffect(() => {
        if (immediately) init();
    }, []);

    return {
        init,
        eventState,
        ticket,
        mainBannerRender,
        drawButtonRender,
        drawModalRender,
        expiredContainerRender,
        winningResultRender,
        fetchEventState,
        fetchTicketState,
    };
}
