import useQueryHook from '@/hooks/useQueryHook';
import { API, URLs } from '@/api';

export const GetTaggedQuestionList = (tagSubject: string = '') => {
    const { data, isLoading, refetch } = useQueryHook(
        ['taggedQuestionList', tagSubject],
        async () => {
            return await API.get(URLs.ina.taggedQuestionList, { tag: tagSubject });
        },
        // {
        //     cacheTime: Infinity,
        //     staleTime: Infinity,
        // },
    );

    return { data, isLoading, refetch };
};

export default GetTaggedQuestionList;
