import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { getCookie } from 'cookies-next';

import useModal from '@/hooks/useModal';
import AppPrompt from '@/containers/modal/AppPrompt';
import { isAppState } from '@/recoil';

const useOpenAppPrompt = () => {
    const { addModal, removeCurrentModal } = useModal();
    const isApp = useRecoilValue(isAppState);
    const passOneDay = getCookie('app-prompt');

    const checkIsMobile = () => {
        if (typeof window !== 'undefined') {
            const UA = window.navigator.userAgent;
            const platform = window.navigator.platform;
            const regex = /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;

            const isMobile = regex.test(UA);
            const isIOS13 =
                (platform && platform.indexOf('iPad') !== -1) ||
                (platform === 'MacIntel' && navigator.maxTouchPoints > 1);

            return isMobile || isIOS13;
        }

        return false;
    };

    useEffect(() => {
        if (checkIsMobile() && !isApp && !passOneDay) {
            addModal({
                key: 'slide',
                props: {
                    content: <AppPrompt />,
                    handleCloseModal: removeCurrentModal,
                },
            });
        }
    }, []);
};

export default useOpenAppPrompt;
