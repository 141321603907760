import { TInsuranceType, TM, TMFETUS, TMSICK, IM, IMFETUS, DE, DM, AC } from '@/models/plan';

interface ITMCard {
    image: string;
    prefixLabel: string;
    suffixLabel: string;
    code: TInsuranceType;
    href: string;
    bgHighlight?: string;
    textHighlight?: string;
    chip?: string;
}

export interface IETCCard {
    image: string;
    prefixLabel: string;
    suffixLabel: {
        default: string;
        small?: string;
    };
    code: TInsuranceType;
}

export interface TMIntroTextImg {
    mainText: string[];
    firstImage: string;
    firstImageHeight: number;
    firstImageWidth: number;
    firstBlueText: string[];
    secondBlueText?: string[];
    compareText: string[];
    compareSubtext: string[];
    compareImg: string;
}

export const TM_ITEMS: ITMCard[] = [
    {
        image: '/img/plan/total_medical_icon@3x.png',
        prefixLabel: '#암보험 #뇌심혈관질환보험',
        suffixLabel: '종합 건강보험',
        code: TM,
        href: '/plan/user-info',
        // bgHighlight: '#eaf2ff',
        textHighlight: '#4f88f6',
        chip: '이슈',
    },
    {
        image: '/img/plan/indemnity_medical_icon@3x.png',
        prefixLabel: '#실손의료비 #실비 ',
        suffixLabel: '실손 의료비보험',
        code: IM,
        href: '/plan/indemnity-type',
        chip: '인기',
    },
    {
        image: '/img/plan/fetus_medical_icon@3x.png',
        prefixLabel: '#태아보험 #산모의료비',
        suffixLabel: '태아보험',
        code: TMFETUS,
        href: '/plan/user-info',
    },
    {
        image: '/img/plan/sick_medical_icon@3x.png',
        prefixLabel: '#병이있어도 #간편가입',
        suffixLabel: '유병자보험',
        code: TMSICK,
        href: '/plan/user-info',
    },
];

export const ETCGuaranteeItems: IETCCard[] = [
    {
        image: '/img/plan/death_medical_icon@3x.png',
        prefixLabel: `준비된 사망보험`,
        suffixLabel: {
            default: `#종신보험 #정기보험 #변액종신보험`,
            small: `#종신보험 #정기보험 #변액종신보험`,
        },
        code: DE,
    },
    {
        image: '/img/plan/demetia_medical_icon@3x.png',
        prefixLabel: `든든한 치매보험`,
        suffixLabel: {
            default: `#간병보험 #치매보험`,
            small: `#간병보험 #치매보험`,
        },
        code: DM,
    },
    {
        image: '/img/plan/accident_medical_icon@3x.png',
        prefixLabel: `사고 및 기타보험`,
        suffixLabel: {
            default: `#운전자보험 #펫보험`,
        },
        code: AC,
    },
];

export const INSCODE: { [key: number]: string } = {
    [TM]: 'comprehensive',
    [TMFETUS]: 'comprehensive',
    [TMSICK]: 'comprehensive',
    [IM]: 'indemnity',
    [IMFETUS]: 'indemnity',
    [DE]: 'death',
    [DM]: 'dementia',
};

export const TMINTRO: { [key: string]: TMIntroTextImg } = {
    TM: {
        mainText: ['종합 건강보험은', '질병, 사고 등 다양한 보장 항목을', '한번에 가입할 수 있는 상품이에요'],
        firstImage: '/img/intro/tm/tmMain.gif',
        firstImageHeight: 261,
        firstImageWidth: 245,
        firstBlueText: [
            '진단비, 수술비와 같은 질병은 물론',
            '상해나 사고, 재산에 대한 손해까지',
            '폭넓게 보장하는 상품이에요',
        ],
        secondBlueText: [
            '보장이 다양하여 수백 가지의 특약이 존재해요!',
            '이 중 나에게 꼭 필요한 보장을 골라서',
            '하나의 상품으로 가입할 수 있어요!',
        ],
        compareText: ['꼭 필요한 주요 질병 진단금을', '선별하여 비교해 드려요'],
        compareSubtext: ['또한, 상해 및 질병에 대한 수술비와 입원비를', '추가하여 보험료를 확인해 볼 수 있어요'],
        compareImg: '/img/intro/tm/tmCompare.gif',
    },
    TMFETUS: {
        mainText: ['태아 보험은 임신 중 가입하고', '아기가 태어난 후부터 건강을', '보장해 주는 보험이에요'],
        firstImage: '/img/intro/tm/fetusMain.gif',
        firstImageHeight: 261,
        firstImageWidth: 270,
        firstBlueText: [
            '태아 보험에는 질병, 상해 보장은 물론',
            '출생 시 발생할 수 있는 선천성 질환과',
            '임신·출산 중 산모 관련 질환까지',
            '보장하는 항목(특약)이 있어요!',
        ],
        compareText: ['꼭 필요한 주요 질병 진단금을', '선별하여 비교해 드려요'],
        compareSubtext: ['또한, 상해 및 질병에 대한 수술비와 입원비를', '추가하여 보험료를 확인해 볼 수 있어요'],
        compareImg: '/img/intro/tm/fetusCompare.gif',
    },
    TMSICK: {
        mainText: ['병력으로 보험 가입이', '거절된 적 있다면', '유병자 보험으로 가입해 보세요'],
        firstImage: '/img/intro/tm/sickMain.gif',
        firstImageHeight: 261,
        firstImageWidth: 270,
        firstBlueText: [
            '유병자 보험은 질병이 있어도 ',
            '가입할 수 있는 보험이에요. 일반 보험에 비해',
            '완화된 심사를 거쳐 가입할 수 있지만',
            '일반 보험 대비 보험료가 비쌀 수 있어요',
        ],
        compareText: ['꼭 필요한 주요 질병 진단금을', '선별하여 비교해 드려요'],
        compareSubtext: ['또한, 상해 및 질병에 대한 수술비와 입원비를', '추가하여 보험료를 확인해 볼 수 있어요'],
        compareImg: '/img/intro/tm/sickCompare.gif',
    },
    IM: {
        mainText: ['실손 의료비 보험은', '병원 치료 시 발생하는', '실제 의료비를 보장해요'],
        firstImage: '/img/intro/tm/imMain.gif',
        firstImageHeight: 261,
        firstImageWidth: 270,
        firstBlueText: [
            '실손 보험은 병원비 중 국민건강보험에서',
            '보장하지 않는 본인 부담 의료 비용을',
            '보장해 주는 보험이에요!',
        ],
        secondBlueText: [
            '크고 작은 병원비에 대비할 수 있고',
            '병원비 부담을 크게 줄여줘',
            '2차 국민건강보험이라고도 합니다!',
        ],
        compareText: ['간단한 정보 입력만으로', '실손 보험을 한 번에 비교해요'],
        compareSubtext: ['회원 가입 없이 생년월일, 성별과 같은', '간단한 정보만으로 비교 할 수 있어요'],
        compareImg: '/img/intro/tm/imCompare.gif',
    },
};
