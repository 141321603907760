import React, { ReactElement } from 'react';
import styled from 'styled-components';

interface IProps {
    children: ReactElement | ReactElement[] | string;
    className?: string;
}

function ShadowContainer({ children, className }: IProps) {
    return <Container className={className}>{children}</Container>;
}

const Container = styled.div`
    border-radius: 10px;
    background-color: #ffffff;
    padding: 18px 20px;
`;

export default ShadowContainer;
