import { useRouter } from 'next/router';
import { useRecoilState, useSetRecoilState } from 'recoil';

import useQueryHook from '@/hooks/useQueryHook';
import { API, URLs } from '@/api';
import { isLoadingState, loginState } from '@/recoil';

type IAction = 'PROFILE' | 'DEFAULT' | 'LOADING';

export const GetUserInfo = (action: IAction = 'DEFAULT') => {
    const router = useRouter();
    const [loginStatus, setLoginStatus] = useRecoilState(loginState);

    const onSuccessAction = () => {
        if (action === 'PROFILE') router.push('/myPage/profile');
    };

    const { refetch: getUserInfo } = useQueryHook(
        ['get-user-info', action],
        async () => {
            return await API.get(URLs.clientInfo.clientInfo);
        },
        {
            onSuccess: res => {
                const plannerInfo = { name: res.recommendedPlannerName, id: res.recommendedPlannerId };
                const clientInfo = {
                    userType: res.tier,
                    clientId: res.id,
                    name: res.clientName,
                    email: res.email,
                };
                plannerInfo.name ??= '';
                plannerInfo.id ??= -1;
                // setLoginStatus({
                //     ...loginStatus,
                //     designatedPlannerName: plannerInfo.name,
                //     designatedPlannerId: plannerInfo.id,
                //     //designatedPlannerCode: res.recoommendedPlannerCode,
                // });
                setLoginStatus({
                    userType: clientInfo.userType === 1 ? 'client' : 'faker',
                    clientId: clientInfo.clientId,
                    name: clientInfo.name,
                    email: clientInfo.email,
                    designatedPlannerName: plannerInfo.name,
                    designatedPlannerId: plannerInfo.id,
                    //designatedPlannerCode: res.recoommendedPlannerCode,
                });
                onSuccessAction();
            },
            onError: err => {
                // console.log('error occurs when get user info : ', err);
            },
            enabled: false,
        },
        action === 'LOADING',
    );

    return { getUserInfo };
};
