import React, { useState } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { useSetRecoilState } from 'recoil';
import styled from 'styled-components';

import LabelUsingAffixIcon from '@/components/ContextPrinter/LabelUsingAffixIcon';
import TextHighlighter from '@/components/ContextPrinter/TextHighlighter';
import SvgSelector from '@/components/Svg/SvgSelector';
import DividerDefault from '@/components/Divider/Default';
import { FontStyle } from '@/utils/StyleUtil';
import { SelectedIns } from '@/recoil/plan';
import { TM_ITEMS } from '@/models/plan/main';
import { TInsuranceToString, TInsuranceType, TM } from '@/models/plan';
import { useRouter } from 'next/router';

const TMGuaranteeMenu = () => {
    if (localStorage.getItem('seeCompare') === null) {
        localStorage.setItem('seeCompare', 'true');
    }
    const [seeCompare, setSeeCompare] = useState(() => {
        if (localStorage.getItem('seeCompare') === 'true') {
            return true;
        } else {
            if (localStorage.getItem('seeCompare') === 'false') return false;
        }
    });

    const setSelectedIns = useSetRecoilState(SelectedIns);
    const router = useRouter();

    const showIntroPageString = localStorage.getItem('showIntroPage');

    if (!showIntroPageString)
        // Matching model, TM = 100, TMFETUS = 120, TMSICK = 130, IM = 500
        localStorage.setItem('showIntroPage', JSON.stringify({ TM: true, TMFETUS: true, TMSICK: true, IM: true }));

    const showIntroPage =
        showIntroPageString !== null
            ? JSON.parse(showIntroPageString)
            : { TM: true, TMFETUS: true, TMSICK: true, IM: true };

    const linkOnClick = (e: React.MouseEvent<HTMLAnchorElement>, code: TInsuranceType, href: string) => {
        e.preventDefault();
        setSelectedIns(code);
        TInsuranceToString(code);
        if (showIntroPage[TInsuranceToString(code)]) {
            router.push(`/tm-intro?ins=${TInsuranceToString(code)}`, '/tm-intro');
        } else {
            router.push(href);
        }
    };

    const chip = (text: string) => {
        return <Chip>{text}</Chip>;
    };

    const CompareFloating = () => {
        setSeeCompare(false);
        localStorage.setItem('seeCompare', 'false');
    };

    return (
        <TotalMedicalContainer>
            <TitleContainer>
                <div className="medical_insurance_text">의료보험</div>
                <div className="subtext">간단한 정보만 입력하면 보험료 비교, 끝!</div>
            </TitleContainer>
            <DividerDefault thickness={1} color="#F1F1F1" />
            <MedicalListContainer>
                {TM_ITEMS.map((item, index) => (
                    <Link key={index} href={item.href}>
                        <a
                            onClick={e => {
                                linkOnClick(e, item.code, item.href);
                            }}
                        >
                            <MedicalContentContainer onClick={() => setSelectedIns(item.code)}>
                                <LabelUsingAffixIconStyled
                                    prefixUri={
                                        <Image src={item.image} alt="total medical icon" width="30px" height="30px" />
                                    }
                                    label={
                                        <LabelContainer>
                                            <TextHighlighter
                                                fontStyle={{}}
                                                bgColor={item.bgHighlight || '#ffffff'}
                                                bgHeight={12}
                                                text={<Tags color={item.textHighlight}>{item.suffixLabel}</Tags>}
                                            />
                                            <h2 className="prefix_text">{item.prefixLabel}</h2>
                                        </LabelContainer>
                                    }
                                    labelStyle={{ marginLeft: '11px' }}
                                    suffixUri={
                                        <ChipChevron>
                                            {item.chip ? chip(item.chip) : null}
                                            <SvgSelector
                                                svg="chevron-right"
                                                width="14px"
                                                height="14px"
                                                stroke="#686868"
                                                strokeWidth={1.2}
                                            />
                                        </ChipChevron>
                                    }
                                />
                            </MedicalContentContainer>
                        </a>
                    </Link>
                ))}
            </MedicalListContainer>
            {seeCompare ? (
                <TempSvg>
                    <svg width="153" height="32" viewBox="0 0 153 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M6 0C2.68629 0 0 2.68629 0 6V20C0 23.3137 2.68629 26 6 26H132.5L134 28L136.52 31.36C136.76 31.68 137.24 31.68 137.48 31.36L141.5 26H147C150.314 26 153 23.3137 153 20V6C153 2.68629 150.314 0 147 0H6Z"
                            fill="#222222"
                        />
                        <g opacity="0.7">
                            <path
                                d="M135.199 8.5L144.199 17.5"
                                stroke="white"
                                strokeWidth="1.2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M144.199 8.5L135.199 17.5"
                                stroke="white"
                                strokeWidth="1.2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </g>
                    </svg>
                    <div className="svgText">보험 상품을 비교해 보세요!</div>
                    <div className="clickBox" onClick={CompareFloating} />
                </TempSvg>
            ) : null}
        </TotalMedicalContainer>
    );
};

const TotalMedicalContainer = styled.div`
    /* transform: translateY(-20px); */
    position: relative;
    padding: 30px 20px;
    border-radius: 10px;
    background-color: #ffffff;
    margin-top: 20px;
    margin-right: 20px;
    margin-left: 20px;
    /* height: 396px; */
`;

const TitleContainer = styled.div`
    ${({ theme }) => theme.flex.flexOnly};
    flex-direction: column;
    align-items: start;
    gap: 5px;
    margin-bottom: 10px;

    .medical_insurance_text {
        margin: 0;
        ${FontStyle(18, 26, '700', '#111111', -1)};
    }

    .hot_text {
        ${FontStyle(12, 18, '500', '#FF2525', -1)};
        align-self: auto;
    }
    .subtext {
        ${FontStyle(12, 20, '400', '#686868', -1)};
    }
`;

const MedicalContentContainer = styled.div`
    /* margin-bottom: 30px;
    & MedicalContentContainer:last-child {
        margin-bottom: 0px;
    } */
`;

// Fix the last category spacing
const LabelUsingAffixIconStyled = styled(LabelUsingAffixIcon)``;

const LabelContainer = styled.div`
    ${({ theme }) => theme.flex.flexColumn};
    align-items: flex-start;

    .prefix_text {
        margin: 0;
        ${FontStyle(12, 20, '400', '#ACACAC', -1)};
    }
`;

const Tags = styled.div<{ color?: string }>`
    margin: 0;
    ${FontStyle(16, 24, '500', '#333333', -1)};
    color: ${({ color }) => color ?? undefined};
`;

const HotContainer = styled.div`
    border-radius: 10px;
    background-color: #ffebeb;
    padding: 1px 8px;
`;

const MedicalListContainer = styled.div`
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 30px;
`;

const Chip = styled.div`
    width: 38px;
    height: 20px;
    border-radius: 10px;
    background: #f0f5ff;
    padding: 1px 8px;
    ${FontStyle(12, 18, '500', '#4F88F6', -1)};
`;

const ChipChevron = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;
`;

const TempSvg = styled.div`
    position: absolute;
    top: 77px;
    right: 20px;
    ${FontStyle(12, 20, '400', 'white', -1)};
    .svgText {
        position: absolute;
        top: 3px;
        left: 8px;
    }
    .clickBox {
        position: absolute;
        height: 14px;
        width: 14px;
        top: 6px;
        right: 8px;
    }
`;

export default TMGuaranteeMenu;
