import React, { useEffect, useState } from 'react';
import Image from 'next/future/image';
import Link from 'next/link';
import styled from 'styled-components';
import { FontStyle } from '@/utils/StyleUtil';
import GetTaggedQuestionList from '@/queries/ina/index/getTaggedQuestionList';
import { useRouter } from 'next/router';
import { ParsedUrlQuery } from 'querystring';

interface ITaggedQuestions {
    id: number;
    title: string;
    ina_question_id: number;
}

const TaggedQuestions = () => {
    const [taggedQuestionList, setTaggedQuestionList] = useState<ITaggedQuestions[]>([
        { id: -999, title: 'error', ina_question_id: -999 },
    ]);

    const [currQuestionIndex, setQuestionIndex] = useState(0);
    const router = useRouter();

    // const campaignQuery = router.query;
    //
    // const decodedQuery: ParsedUrlQuery = {};
    //
    // for (const key in campaignQuery) {
    //     const value = campaignQuery[key];
    //
    //     if (typeof value === 'string') {
    //         decodedQuery[key] = decodeURIComponent(value);
    //     } else if (Array.isArray(value)) {
    //         decodedQuery[key] = value.map(decodeURIComponent);
    //     } else {
    //         decodedQuery[key] = value;
    //     }
    // }
    //
    // const campaignCategory = campaignQuery.utm_campaign;
    // const campaignTerm = campaignQuery.utm_term;
    // const campaignSource = campaignQuery.utm_source;
    //
    // const sessionCampaignCategory = window.sessionStorage.getItem('campaign-category');
    //
    // if (
    //     sessionCampaignCategory === null &&
    //     campaignCategory !== undefined &&
    //     campaignTerm !== undefined &&
    //     campaignSource !== undefined
    // ) {
    //     window.sessionStorage.setItem('campaign-category', campaignCategory?.toString());
    //     window.sessionStorage.setItem('campaign-term', campaignTerm?.toString());
    //     window.sessionStorage.setItem('campaign-source', campaignSource?.toString());
    // }

    const sessionCampaignCategory = window.sessionStorage.getItem('campaign-category');

    const toUseCampaignCategory = sessionCampaignCategory ?? '';

    const [queryKeyExists, setQueryKeyExists] = useState(true);

    const { data, isLoading, refetch } = GetTaggedQuestionList(queryKeyExists ? toUseCampaignCategory : '');

    useEffect(() => {
        refetch();
        if (!isLoading) {
            if (data.length !== 0) setTaggedQuestionList(data);
            else {
                setQueryKeyExists(false);
            }
        }
    }, [isLoading]);

    const getNextQuestion = () => {
        if (currQuestionIndex < taggedQuestionList.length - 1) setQuestionIndex((q: number) => q + 1);
        else setQuestionIndex(0);
    };
    const goToInAPage = () => {
        router.push('/ina');
    };

    const goToQuestion = (questionId: number) => {
        router.push(`/ina/${questionId}`);
    };

    return (
        <OuterContainer>
            <TitleContainer>
                <Title>
                    <Image src="/img/ina/ico_main_light.png" alt="idea" height={22} width={22} />
                    <div>보험? 무엇이든 물어보세요!</div>
                </Title>
                <button onClick={getNextQuestion}>
                    <Image src="/img/ina/ico_main_new.png" alt="idea" height={30} width={30} />
                </button>
            </TitleContainer>
            <QuestionContainer>
                <QuestionPart>
                    <Image src={'/img/ina/curious.gif'} alt="curious" width={46} height={46} />
                    {/* <Lottie animationData={animatedFace} /> */}
                    {!isLoading && <div>{taggedQuestionList[currQuestionIndex].title}</div>}
                </QuestionPart>
                <ButtonContainer>
                    <SubmitQuestion onClick={goToInAPage}>나의 질문 등록하기</SubmitQuestion>
                    <CheckAnswer onClick={() => goToQuestion(taggedQuestionList[currQuestionIndex].ina_question_id)}>
                        답변 확인하기
                    </CheckAnswer>
                </ButtonContainer>
            </QuestionContainer>
        </OuterContainer>
    );
};

const OuterContainer = styled.div`
    padding-top: 18px;
    padding-left: 20px;
    padding-right: 20px;
    margin-right: 20px;
    margin-left: 20px;
    border-radius: 10px;
    background-color: #eef9ff;
    margin-top: 20px;
`;

const QuestionContainer = styled.div`
    position: relative;
    border-radius: 10px;
    background-color: #fff;
    padding: 20px;
    margin-top: 19px;
    margin-left: -20px;
    margin-right: -20px;
`;

const QuestionPart = styled.div`
    display: flex;
    align-items: center;
    flex-shrink: 0;
    gap: 8px;
    ${FontStyle(14, 22, '500', '#222', -1)};
`;

const TitleContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    ${FontStyle(16, 24, '700', '#111', -1)};
`;

const Title = styled.div`
    display: flex;
    gap: 4px;
`;

const ButtonContainer = styled.div`
    margin-top: 19px;
    display: flex;
    justify-content: center;
    gap: 8px;
    flex-basis: 0;
`;

const SubmitQuestion = styled.button`
    width: 100%;
    text-align: center;
    padding: 12px 0;
    border-radius: 6px;
    border: 1px solid #00a2d5;
    background-color: #fff;
    ${FontStyle(14, 22, '500', '#00A2D5', -1)};
`;

const CheckAnswer = styled.button`
    width: 100%;
    text-align: center;
    padding: 12px 0;
    border-radius: 6px;
    background-color: #00a2d5;
    ${FontStyle(14, 22, '500', '#FFF', -1)};
`;

export default TaggedQuestions;
