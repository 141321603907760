import React from 'react';
import Image from 'next/image';
import router from 'next/router';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import styled from 'styled-components';

import { isLoadingState, isLoginState } from '@/recoil';
import { unReadChattingCntState } from '@/recoil/chat';
import { GetUserInfo } from '@/queries/myPage/index/getUserInfo';
import { FontStyle } from '@/utils/StyleUtil';
import { TABLET, RESIZED_SCREEN_TABLET } from '@/models/breakPoints';

type TItemKeys = 'chat' | 'ina' | 'plan' | 'myPlan' | 'myPage';

interface ILayout {
    children: React.ReactNode;
    selectedItemKey: TItemKeys;
    paddingSize?: number;
}

interface ITabsData {
    key: TItemKeys;
    label: string;
    route: string;
}

const TabsData: ITabsData[] = [
    { key: 'chat', label: '채팅', route: 'chat' },
    { key: 'ina', label: '보험Q&A', route: 'ina' },
    { key: 'plan', label: '홈', route: '' },
    { key: 'myPlan', label: '내보험상품', route: 'myPlan' },
    { key: 'myPage', label: '설정', route: 'myPage' },
];

const BottomTabLayout = ({ children, selectedItemKey }: ILayout) => {
    const isLogin = useRecoilValue(isLoginState);
    const chattingCnt = useRecoilValue(unReadChattingCntState);
    const setIsLoading = useSetRecoilState(isLoadingState);
    const { getUserInfo } = GetUserInfo('LOADING');

    const TabItemRender = (tab: ITabsData) => {
        const isSelected = selectedItemKey === tab.key;

        return (
            <TabItemContainer
                key={tab.key}
                isSelected={isSelected}
                onClick={async () => {
                    // prettier-ignore
                    // eslint-disable-next-line no-useless-escape
                    const regex = new RegExp('\/ina*');
                    if (tab.key === 'ina' && !regex.test(router.asPath)) {
                        setIsLoading(true);
                    }
                    await getUserInfo();
                    if (tab.key === 'ina' && regex.test(router.asPath)) router.push(router.asPath);
                    else {
                        router.push(`/${tab.route}`).then(() => {
                            if (router.asPath === '/chat' && !isSelected && isLogin) setIsLoading(true);
                        });
                    }
                }}
            >
                <ImageContainer>
                    <Image
                        src={isSelected ? `/img/layout/${tab.key}_s@3x.png` : `/img/layout/${tab.key}@3x.png`}
                        alt={tab.key}
                        width="30px"
                        height="30px"
                    />
                    {tab.key === 'chat' && isLogin && chattingCnt > 0 && (
                        <ChattingCnt>{chattingCnt < 100 ? chattingCnt : '99+'}</ChattingCnt>
                    )}
                </ImageContainer>
                <div className="label">{tab.label}</div>
            </TabItemContainer>
        );
    };
    return (
        <Container id="bottom_tab_layout">
            {children}
            <BottomTabContainer>
                <BottomTabItemsContainer>{TabsData.map(tab => TabItemRender(tab))}</BottomTabItemsContainer>
            </BottomTabContainer>
        </Container>
    );
};

const Container = styled.div`
    height: 100%;
`;

const BottomTabContainer = styled.div`
    ${({ theme }) => theme.flex.flexColumn}
    background-color: #ffffff;
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 57px;
    /* border-top: 1px solid #dadada; */

    @media (min-width: ${TABLET}px) {
        max-width: ${RESIZED_SCREEN_TABLET}px;
    }
`;

const BottomTabItemsContainer = styled.div`
    ${({ theme }) => theme.flex.flexOnly}
    ${({ theme }) => theme.flex.justifyAround}
    padding: 4px 0 6px 0;
`;

const TabItemContainer = styled.div<{ isSelected: boolean }>`
    ${({ theme }) => theme.flex.flexColumn}
    ${({ theme }) => theme.flex.justifyCenter}
    flex: 1;
    position: relative;
    .label {
        ${props =>
            props.isSelected ? FontStyle(12, 16, '500', '#222222', -0.8) : FontStyle(12, 16, '400', '#888888', -0.8)}
    }
`;

const ImageContainer = styled.div`
    position: relative;
    width: 30px;
    height: 30px;
`;

const ChattingCnt = styled.div`
    ${({ theme }) => theme.flex.flexOnly}
    ${({ theme }) => theme.flex.justifyCenter}
    position: absolute;
    top: 0;
    left: 22px;
    padding: 1px 4px;
    height: 14px;
    border-radius: 40px;
    background-color: red;
    ${FontStyle(12, 16, '400', '#ffffff')};
`;

export default React.memo(BottomTabLayout);
